<template>
    <v-card :loading="loading">
        <v-toolbar flat>
            <v-toolbar-title>Список</v-toolbar-title>
            <v-spacer />
            <v-btn icon :to="{ name: 'AuthorOne', params: { id: 0 } }">
                <v-icon>mdi-plus</v-icon>
            </v-btn>
        </v-toolbar>
        <v-list>
            <v-list-item
                v-for="l in list"
                :key="l.id"
                :to="{ name: 'AuthorOne', params: { id: l.id } }"
            >
                <v-list-item-avatar>
                    <img
                        :src="`${$store.state.uploads}/author/${
                            l.id
                        }.jpg?t=${Date.now()}`"
                        width="100"
                        height="100"
                    />
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-title>{{ l.name }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-card>
</template> 

<script>
export default {
    name: "AuthorList",
    data: () => ({
        loading: false,
        list: [],
    }),
    mounted() {
        this.getList();
    },
    methods: {
        async getList() {
            this.loading = true;
            const { list } = await this.$admin("/author");
            this.list = list;
            this.loading = false;
        },
    },
};
</script>
